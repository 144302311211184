




































































































.auth {
  width: 100%;
  height: 100%;
  height: 100vh;
  max-height: 100vh;

  .left-auth {
    max-width: 500px;
    box-shadow: -10px 22px 90px 0 rgb(0 0 0 / 8%);
    position: fixed;
    flex: 1;
    transform: unset;
    top: 0;
    bottom: 0;
    left: unset;
    right: 0;
    display: flex;
    flex-direction: column;
    background: rgb(0 0 20 / 73%);
    z-index: 1;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 50%;
    @media (min-width: 576px) {
      max-height: 100vh;
      padding: 10px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .right-auth {
    background: url('~@/assets/images/background/bg.png'), #f8f8f8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    img {
      width: 100%;
      max-width: 120px;
      height: 115px;
    }
    h5 {
      color: #fff;
      margin: 40px auto 5px auto;
      font-size: clamp(15px, 1.5vw, 17px);
      font-weight: 600;
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 3px;
        width: 40%;
        margin: auto;
        background: #009750;
      }
    }
    h1 {
      color: #fff;
      margin: 20px auto 5px auto;
      font-size: clamp(19px, 2.5vw, 35px);
      font-weight: 600;
    }
    h6.help {
      color: #fff;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      max-width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }
    .help-container {
      a {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
