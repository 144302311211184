// Your variable overrides
$body-bg: #fff;
$body-color: #111;

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
@import url("./font.scss");
html,
body {
    transition: all ease-in 0.1s;
    font-family: "Nunito", "Titillium Web", sans-serif;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-weight: 400;
    font-style: normal;
}
html {
    scrollbar-width: auto;
    scrollbar-color: #d6d6d6;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(63, 39, 75, 0.801);
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d6d6d6;
        border-radius: 10px;
        border: 1px solid #ffffff;
    }
}
.card .bg-info{
  background-color: #0c83e9!important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.btn-animation-info{
  color: #fff;
  background-color: #0c83e9;
  transition: 0.3s;
  border-color: #0c83e9;
  animation: glowing 2s linear infinite;
}
.btn-animation-info:hover{
  color: #fff;
  transform: scale(1.1);
}
.min-width-160{
  min-width: 160px;
}
@keyframes glowing{
  0% { box-shadow: 0 0px 30px #0c83e9 }
  50% { box-shadow: 0 0px 0px #0c83e9 }
  100% { box-shadow: 0 0px 30px #0c83e9 }
}
.btn-info {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
}
@media (min-width: 1024px) {
    .xxl-3 {
        flex: 0 0 500px;
        max-width: 500px;
    }
    .xxl-9 {
        flex: 1 1 auto;
        max-width: calc(100% - 500px);
    }
}
.dropdown-item.active,
.dropdown-item:hover {
    background: #0c83e9;
    color: #fff;
    i,svg{
        color: #fff;
    }
}
.table {
  thead{
    background: #0c83e9;
    th {
      vertical-align: middle;
      padding: 7px 10px;
      color:#fff;
      border: 0px solid rgb(255 255 255/ 40%);
      border-left: 0.5px solid rgb(255 255 255/ 10%);
      border-right: 0.5px solid rgb(255 255 255/ 10%);
      width: 100%;
      min-width: 200px;
    }
  }
  tbody{
    tr{
      td{
        border: 0;
        color: #fff;
        border-left: 0.5px solid rgb(255 255 255/ 10%);
        border-right: 0.5px solid rgb(255 255 255/ 10%);
      }
      &:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.1);
      }
      &:nth-last-child(n){
        border-bottom: 0.5px solid rgb(255 255 255/ 10%);
      }
    }
  }
}
.fz-md{
  font-size: 1.4rem!important;
}
.card {
    background-color: rgba(0, 0, 20, 0.5);
    border: 1px solid rgb(255 255 255);
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    .card-header{
      border-bottom: 1px solid rgba(255, 255, 255, 0.125);
      text-align: center;
      padding: 20px;
      h3{
        font-size: 28px;
        font-weight: 600;
      }
      p{
        margin-bottom: 0;
      }
    }
    .card-body{
      .table-search{
        // display: flex;
        margin-bottom: 10px;
        input{
          display: inline-block;
          width: 160px;
        }
      }
    }
}
.pa-2{
  padding: 10px!important;
}
.dropdown-item {
    i,
    svg {
        color: #a8a8b1;
        margin-right: 5px;
    }
}
.btn-egg {
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px 0 #ccc;
    letter-spacing: 0.05rem;
    position: relative;
    background: #009750;
    color: #fff !important;
    min-width: 130px;
    width: auto;
    min-width: 150px;
    max-width: 225px;
    height: auto;
    min-height: 35px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    border-radius: 0.3rem;
    z-index: 1;
    &:hover,
    &:focus,
    &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
    }
}
.cursor-pointer {
    cursor: pointer;
}
:root {
    --animate-duration: 0.9s;
    --animate-delay: 0.2s;
    --animate-repeat: 1;
}
@media (min-width: 2000px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1440px;
    }
}
@media (min-width: 2560px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1600px;
    }
}
@media (min-width: 3000px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 2100px;
    }
}

.toast-success {
    background-color: #009750 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    .toast-message {
        font-weight: 600 !important;
        font-family: "Nunito", "Titillium Web", sans-serif !important;
    }
}
.toast-error {
    background-color: #b91c30 !important;
    border-radius: 5px  !important;
    opacity: 1 !important;
    .toast-message {
        font-weight: 600 !important;
        font-family: "Nunito", "Titillium Web", sans-serif !important;
    }
}
