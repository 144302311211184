



































































































.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 3.9375rem;
  background-color: rgb(0 0 20 / 73%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 80px;
  z-index: 1010;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  .left-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 270px;
    @media (max-width: 1200px) {
      justify-content: flex-start;
    }
    a {
      text-decoration: none;
    }
    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .logo-img {
        img {
          height: 2.5rem;
          width: auto;
        }
      }
      .text-logo {
        margin-left: 5px;
        span {
          &.effect {
            background: #009750;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            line-height: 120%;
          }
          &.eggsbook {
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            color: #5d5d5d;
            line-height: 120%;
          }
        }
      }
    }
    .menu-icon {
      margin-left: 3.25rem;
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 374px) {
        margin-left: 5px;
      }
      button {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        background: transparent;
        color: #fff;
        border: 0;
        img {
          width: 35px;
        }
      }
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .wallet {
      padding: 5px 10px;
      border: 1px solid #0c83e9;
      border-radius: 5px;
      margin: 0px 5px;
      p {
        margin-bottom: 0px;
        font-weight: bold;
        color: #0c83e9;
      }
    }
    .item-header {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;
      }
    }
    .avatar {
      color: #6c757d;
      box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
