





























































































































.left-sidebar {
  min-width: 270px;
  max-width: 270px;
  height: 100vh;
  background-color: rgba(0, 0, 20, 0.73);
  // min-height: 500px;
  min-height: calc(100vh - (250px - 3.9375rem));
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  transition: left 0.2s ease-in, margin-right 0.2s ease-in;
  transition: 0.3s;
  padding: 40px 0 60px 0;
  margin-top: 0px;
  border-radius: 3px;
  position: fixed;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1020;
  @media (max-width: 1200px) {
    position: fixed;
    min-height: calc(100vh - 4rem);
    top: 4rem;
    left: 0;
    padding-bottom: 40px;
    background-color: rgb(0 0 20 / 95%);
    bottom: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 320px;
    min-height: unset;
    max-height: calc(100vh - 4rem);
    height: 100%;
  }
  .sidebar-title {
    width: 100%;
    position: relative;
    .logo-img {
      padding: 40px;
      img {
        width: 100%;
      }
    }
    .text-logo {
      font-size: 18px;
      color: #fff;
      text-align: center;
      span {
        color: #ec1c24;
      }
    }
    .box-action {
      padding: 15px 25px;
      color: #fff;
      font-size: 16px;
      .line-bottom {
        width: 100%;
        height: 1px;
        background: rgb(255, 255, 255, 0.3);
        display: block;
        margin: 10px 0 0;
      }
      .action-group {
        margin-bottom: 10px;
        display: inline-block;
        align-items: center;
        width: 100%;
        .lable {
          margin: 0;
          margin-top: 5px;
        }
        .switch {
          margin: 0px;
          margin-left: 15px;
          position: relative;
          display: inline-block;
          width: 50px;
          float: right;
          height: 28px;
          input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            height: 28px;
            border: 1px #ec1c24 solid;
            bottom: 0;
            background-color: #fff;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          .slider:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 1px;
            bottom: 3px;
            background-color: #666666;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          input:checked + .slider {
            background-color: #fff;
          }
          input:focus + .slider {
            box-shadow: 0 0 1px #fff;
          }
          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          .slider.round {
            border-radius: 34px;
          }
          .slider.round:before {
            border-radius: 50%;
            background-color: #666666;
          }
          input:checked + .slider:before {
            background-color: #00af00;
          }
        }
      }
    }
  }
  .left-sidebar-content {
    min-height: 400px;
    .break-sidebar-content {
      border-top: 1px solid #e1e1e1;
      width: 100%;
      opacity: 0.5;
      background: linear-gradient(#f8f8f8, transparent);
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 4px;
    }
  }

  .menu-item {
    margin: 12px 0;
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-left: 3px solid transparent;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      text-decoration: none;
      padding: 7px 20px;
      i,
      svg {
        width: 30px;
        display: inline-block;
        font-size: 20px;
        opacity: 0.8;
        vertical-align: middle;
        color: #fff;
        margin-right: 5px;
      }
      span {
        display: block;
        color: #fff;
        line-height: 1;
      }
      &.active {
        span {
          color: #008cff;
        }
        font-weight: 600;
        border-left-color: #008cff;
        background: #25485859;
        i,
        svg {
          color: #008cff;
        }
      }
      &.sub {
        align-items: initial;
        i,
        svg {
          color: #009750;
        }
        span {
          background: #009750;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: clamp(14px, 1.5vw, 16px);
          text-transform: uppercase;
          font-weight: 700;
          line-height: 150%;
        }
      }
    }
    .help-container {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      border-top: 3px solid rgba(0, 0, 0, 0.09);
      a {
        width: 30px;
        height: 30px;
        margin: 0 5px;
        padding: 0;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .menu-support {
    background: #009750;
    border-radius: 10px;
    position: sticky;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
    top: calc(100% - 2rem - 40px);
    height: 50px;
    width: calc(100% - 1.2rem);
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      top: calc(100% - 40px - 7rem);
    }
    i,
    svg {
      width: 30px;
      display: inline-block;
      font-size: 20px;
      opacity: 0.8;
      vertical-align: middle;
      color: #fff;
      margin-right: 5px;
    }
    a {
      min-height: 50px;
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      span {
        color: #fff;
        font-weight: 600;
      }
    }
  }
}
